import { type IShift } from '../../../models/IShift';
import './style.css';
import writeXlsxFile from 'write-excel-file';
import {} from 'xlsx-js-style';
import dayjs, { type Dayjs } from '../../../utils/dayjs';
import { type IMedicalGroup } from '../../../models/IMedicalGroup';

interface exportShiftsProps {
  shiftsParam: IShift[];
  hospitalName: string;
  medicalGroup?: IMedicalGroup | null;
  date: Date;
}

type DataType = {
  value: string | number;
  width?: number;
  fontSize?: number;
  align?: 'center' | 'right' | 'left';
  color?: string;
  fontWeight?: 'bold' | undefined;
  backgroundColor?: string;
  topBorderColor?: string;
  bottomBorderColor?: string;
  span?: number;
  rowSpan?: number;
} | null;

function ExportShifts({
  shiftsParam,
  hospitalName,
  medicalGroup,
  date,
}: exportShiftsProps) {
  if (!medicalGroup) {
    return <></>;
  }

  const startDate = dayjs()
    .set('date', 1)
    .set('month', date.getMonth())
    .set('year', date.getUTCFullYear())
    .set('hour', 0)
    .set('minute', 0);
  const endDate = startDate.endOf('month').set('hour', 23).set('minute', 59);

  const header: DataType[] = [
    {
      value: 'Profissional',
      fontWeight: 'bold',
      align: 'left',
      backgroundColor: '#9EDCFF',
    },
    {
      value: 'Data',
      fontWeight: 'bold',
      align: 'center',
      backgroundColor: '#9EDCFF',
    },
    {
      value: 'Hora de início',
      fontWeight: 'bold',
      align: 'center',
      backgroundColor: '#9EDCFF',
    },
    {
      value: 'Hospital',
      fontWeight: 'bold',
      align: 'center',
      backgroundColor: '#9EDCFF',
    },
    {
      value: 'Grupo',
      fontWeight: 'bold',
      align: 'center',
      backgroundColor: '#9EDCFF',
    },
    {
      value: 'Duração (horas)',
      fontWeight: 'bold',
      align: 'center',
      backgroundColor: '#9EDCFF',
    },
    {
      value: 'Valor',
      fontWeight: 'bold',
      align: 'center',
      backgroundColor: '#9EDCFF',
    },
    {
      value: 'Check In',
      fontWeight: 'bold',
      align: 'center',
      backgroundColor: '#9EDCFF',
      span: 3,
    },
    null,
    null,
    {
      value: 'Check Out',
      fontWeight: 'bold',
      align: 'center',
      backgroundColor: '#9EDCFF',
      span: 3,
    },
    null,
    null,
    {
      value: 'Hora trabalhada',
      fontWeight: 'bold',
      align: 'center',
      backgroundColor: '#9EDCFF',
      span: 3,
    },
    null,
    null,
    {
      value: 'Valor hora trabalhada',
      fontWeight: 'bold',
      align: 'center',
      backgroundColor: '#9EDCFF',
      span: 3,
    },
    null,
    null,
    {
      value: 'Localização (Check in)',
      fontWeight: 'bold',
      align: 'center',
      backgroundColor: '#9EDCFF',
      span: 5,
    },
    null,
    null,
    null,
    null,
    {
      value: 'Localização (Check out)',
      fontWeight: 'bold',
      align: 'center',
      backgroundColor: '#9EDCFF',
      span: 5,
    },
    null,
    null,
    null,
    null,
  ];
  const formatDate = (x: Date) => {
    return (
      ('0' + x.getDate()).slice(-2) +
      '/' +
      ('0' + (x.getMonth() + 1)).slice(-2) +
      '/' +
      x.getFullYear()
    );
  };

  const formatHour = (x: number) => {
    return (
      Math.floor(x) +
      'h' +
      ((x - Math.floor(x)) * 0.6).toFixed(2).toString().slice(2, 4)
    );
  };

  const formatStartEnd = (x: Dayjs) => {
    return x.get('h') + ':' + ('0' + x.get('m')).slice(-2);
  };

  const shifts = shiftsParam.filter(
    (shift) => medicalGroup.guid === shift.medicalGroup?.guid,
  );

  const professionals = shifts
    .map((shift) => shift.responsibleUser)
    .sort((a, b) => a?.name?.localeCompare(b?.name ?? '') ?? 0);
  const cleanProfessionals = professionals.filter(
    (item, index) =>
      professionals.findIndex((p) => p?.guid === item?.guid) === index,
  );

  const roundNumber = (num: number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  const moneyFormat = (value: number) => {
    let valueFormated = 'R$ ' + value.toFixed(2);

    valueFormated = valueFormated.replace('.', ',');

    return valueFormated;
  };

  const generateXls = async () => {
    const data: DataType[][] = [
      [
        {
          value: '',
        },
        {
          value: '',
        },
        {
          value: '',
        },
        {
          value: '',
        },
        {
          value: '',
        },
        {
          value: '',
        },
        {
          value: 'Emitido em ' + formatDate(new Date()),
          align: 'right',
        },
      ],
      [],
      [
        {
          value: 'Agendoctor | Financeiro',
          color: '#009FFA',
          fontSize: 20,
        },
        {
          value: '',
        },
        {
          value: '',
        },
        {
          value: '',
        },
        {
          value: '',
        },
        {
          value: '',
        },
        {
          value: `${startDate.format('DD/MM/YYYY')} à ${endDate.format('DD/MM/YYYY')}`,
        },
      ],
      [],
    ];

    cleanProfessionals
      .filter((professional) => {
        const professionalShifts = shifts.filter(
          (shift) => shift.responsibleUserGuid === professional?.guid,
        );

        return (
          professionalShifts.filter(
            (shift) =>
              dayjs(shift.startDate).isAfter(startDate) &&
              dayjs(shift.startDate).isBefore(endDate),
          ).length > 0
        );
      })
      .forEach((professional) => {
        const professionalShifts = shifts.filter(
          (shift) => shift.responsibleUserGuid === professional?.guid,
        );

        let totalValue: number = 0;
        let totalHours: number = 0;
        let totalWorkedHours: number = 0;
        let totalWorkedHoursValue: number = 0;

        data.push(header);

        professionalShifts
          .filter(
            (shift) =>
              dayjs(shift.startDate).isAfter(startDate) &&
              dayjs(shift.startDate).isBefore(endDate),
          )
          .forEach((shift, i) => {
            const shiftValue = Number(shift.value);

            totalValue += shiftValue;

            const shiftStart = dayjs(shift.startDate);
            const shiftEnd = dayjs(shift.endDate);

            const checkIn = shift.checkInOut
              ? dayjs(shift.checkInOut?.checkInAt)
              : null;
            const checkOut = shift.checkInOut?.checkOutAt
              ? dayjs(shift.checkInOut?.checkOutAt)
              : null;

            const workedHours =
              checkIn && checkOut ? checkOut.diff(checkIn, 'minute') / 60 : 0;

            totalWorkedHours += workedHours;

            const shiftHours = shiftEnd.diff(shiftStart, 'hour', true);
            totalHours += shiftHours;

            const hourValue = roundNumber(shiftValue / shiftHours);

            const workedHoursValue = roundNumber(hourValue * workedHours);
            totalWorkedHoursValue += workedHoursValue;

            data.push([
              {
                value: professional?.name ?? '',
                align: 'left',
                backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
              },
              {
                value: dayjs(shift.startDate).format('DD/MM/YYYY'),
                align: 'center',
                backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
              },
              {
                value: shiftStart.format('HH:mm'),
                align: 'center',
                backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
              },
              {
                value: hospitalName,
                align: 'center',
                backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
              },
              {
                value: medicalGroup.name,
                align: 'center',
                backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
              },
              {
                value: formatHour(shiftHours),
                align: 'center',
                backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
              },
              {
                value: moneyFormat(shiftValue),
                align: 'center',
                backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
              },
              {
                value: checkIn ? formatStartEnd(checkIn) : '',
                align: 'center',
                backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
                span: 3,
              },
              null,
              null,
              {
                value: checkOut ? formatStartEnd(checkOut) : '',
                align: 'center',
                backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
                span: 3,
              },
              null,
              null,
              {
                value: formatHour(workedHours),
                align: 'center',
                backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
                span: 3,
              },
              null,
              null,
              {
                value: moneyFormat(workedHoursValue),
                align: 'center',
                backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
                span: 3,
              },
              null,
              null,
              {
                value: shift.checkInOut?.checkInPlacemark ?? '',
                align: 'center',
                backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
                span: 5,
              },
              null,
              null,
              null,
              null,
              {
                value: shift.checkInOut?.checkOutPlacemark ?? '',
                align: 'center',
                backgroundColor: i % 2 === 0 ? '#DFF3FF' : undefined,
                span: 5,
              },
              null,
              null,
              null,
              null,
            ]);
          });

        totalValue = roundNumber(totalValue);
        totalWorkedHours = roundNumber(totalWorkedHours);

        data.push([
          {
            value: 'Total',
            fontWeight: 'bold',
            backgroundColor: '#9EDCFF',
            align: 'center',
          },
          {
            value: '',
            backgroundColor: '#9EDCFF',
            span: 4,
          },
          null,
          null,
          null,
          {
            value: formatHour(totalHours),
            align: 'center',
            fontWeight: 'bold',
            backgroundColor: '#9EDCFF',
          },
          {
            value: moneyFormat(totalValue),
            align: 'center',
            fontWeight: 'bold',
            backgroundColor: '#9EDCFF',
          },
          {
            value: '',
            backgroundColor: '#9EDCFF',
            span: 6,
          },
          null,
          null,
          null,
          null,
          null,
          {
            value: formatHour(totalWorkedHours),
            backgroundColor: '#9EDCFF',
            span: 3,
            align: 'center',
          },
          null,
          null,
          {
            value: moneyFormat(totalWorkedHoursValue),
            align: 'center',
            fontWeight: 'bold',
            backgroundColor: '#9EDCFF',
            span: 3,
          },
          null,
          null,
          {
            value: '',
            backgroundColor: '#9EDCFF',
            span: 10,
          },
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ]);

        data.push([]);
      });
    await writeXlsxFile(data, {
      fileName:
        `${date.toLocaleString('default', { month: 'long', year: 'numeric' })}-${medicalGroup.name}-relatorio.xlsx`
          .toLowerCase()
          .replaceAll(' ', '-'),
      columns: [
        { width: 25 },
        { width: 12 },
        { width: 18 },
        { width: 20 },
        { width: 20 },
        { width: 22 },
        { width: 20 },
      ],
    });
  };

  return (
    <>
      <span
        onClick={async () => {
          await generateXls();
        }}
      >
        Gerar Relatório
      </span>
    </>
  );
}

export default ExportShifts;
