import dayjs from '../../../utils/dayjs';
import { z } from 'zod';

export enum ShiftFormFields {
  Professional = 'professional',
  StartHour = 'startHour',
  EndHour = 'endHour',
  Value = 'value',
  Observation = 'observation',
  EditAllRelated = 'editAllRelated',
  ShiftId = 'shiftId',
  NeedsNextDay = 'needsNextDay',
}

export const initialShiftValues = {
  professional: '',
  startHour: '',
  endHour: '',
  value: '',
  observation: '',
  editAllRelated: false,
  shiftId: '',
  needsNextDay: false,
};

export const shiftValidationSchema = z.object({
  professional: z
    .string()
    .refine((i) => {
      console.log(i);

      if (!i) return true;

      const result = z.string().uuid().safeParse(i);

      return result.success;
    })
    .optional()
    .transform((i) => {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      return i || null;
    }),
  startHour: z
    .string({
      required_error: 'Obrigatório',
    })
    .trim()
    .refine((i) => {
      const date = new Date(`2021-01-01T${i}:00.000Z`);
      return !isNaN(date.getTime());
    }, 'Hora inválida')
    .transform((i) => dayjs(i, 'HH:mm')),
  endHour: z
    .string({
      required_error: 'Obrigatório',
    })
    .trim()
    .refine((i) => {
      const date = new Date(`2021-01-01T${i}:00.000Z`);
      return !isNaN(date.getTime());
    }, 'Hora inválida')
    .transform((i) => dayjs(i, 'HH:mm')),
  value: z
    .string({
      required_error: 'Obrigatório',
    })
    .trim()
    .transform((i) => Number(i))
    .refine((i) => {
      return !!i && !isNaN(i);
    }, 'Valor inválido'),
  observation: z.string().optional(),
  editAllRelated: z.boolean(),
  shiftId: z.string(),
  needsNextDay: z.boolean(),
});

export type ShiftFormValues = z.infer<typeof shiftValidationSchema>;
