/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Form from '../../../components/form';
import Grid from '@mui/material/Grid';
import './style.css';
import Button from '../../../components/button';
import { useEffect, useState } from 'react';
import { Autocomplete, TextField as MuiTextField } from '@mui/material';
import { ufsList } from '../../../utils/ufs';
import { useAuth } from '../../../context/AuthContext';
import { toast } from 'react-toastify';
import { type IUser } from '../../../models/IUser';
import { HospitalService } from '../../../api/hospital';
import Modal from '../../../components/modal';
import { CepService } from '../../../api/cep';
import AlertsApi from '../../../api/alerts';
import { FormProvider } from 'react-hook-form';
import useResponsible from '../hooks/useResponsible';
import TextField from '../../../components/TextField';
import {
  ResponsibleFields,
  type ResponsibleValues,
} from '../forms/responsibleForm';
import PhoneField from '../../../components/PhoneField';
import SelectField from '../../../components/SelectField';
import DateField from '../../../components/DateField';
import useGroup from '../hooks/useGroup';
import { GroupFields, type GroupValues } from '../forms/groupForm';
import { Plus } from 'lucide-react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export interface addFields {
  guid?: string;
  groupName?: string;
  managers?: IUser[];
}

export interface addProfessionalFields {
  guid?: string;
  name?: string;
  email?: string;
  phone1?: string;
  phone2?: string;
  profession?: string;
  crm?: string;
  ufCrm?: string;
  // adress
  cep?: string;
  street?: string;
  number?: string;
  district?: string;
  complement?: string;
  uf?: string;
  city?: string;
  // details
  admissionDate?: string;
  code?: string;
  details?: string;
}

interface AddTurnoProps {
  editData?: addFields;
  setEditData: (x: addFields) => void;
  setIsVisible: (x: boolean) => void;
  onSuccess: () => void;
}
function AddTurno({
  editData,
  setEditData,
  setIsVisible,
  onSuccess,
}: AddTurnoProps) {
  const navigate = useNavigate();
  const [, setIsSaveEnabled] = useState(false);
  const [isSaveProfessionalEnabled, setIsSaveProfessionalEnabled] =
    useState(false);
  const [data] = useState<addFields | undefined>(editData);
  const [professionalData, setProfessionalData] = useState<
    addProfessionalFields | undefined
  >(editData);
  const [users, setUsers] = useState<IUser[]>([]);
  const [isAddProfessionalVisible, setIsAddProfessionalVisible] =
    useState(false);
  const [ínitialManagers, setInitialManagers] = useState<string[]>([]);
  const {
    methods: responsibleMethods,
    onError,
    onSubmit: onSubmitResponsible,
  } = useResponsible();
  const { methods: groupMethods, onSubmit: onGroupSubmit } = useGroup();
  const [managers, setManagers] = useState<IUser[]>(data?.managers ?? []);
  const auth = useAuth();

  const { isPending: isSaving, mutate: mutateSaveGroup } = useMutation({
    mutationFn: async (input: GroupValues) => {
      await onGroupSubmit({ ...input, manager: professionalData });
    },
    onSuccess() {
      onSuccess();
      toast.success('Grupo criado com sucesso!');
      navigate('/groups');
    },
    onError() {
      toast.error('Erro ao criar o grupo');
    },
  });

  const fetchUsers = async () => {
    if (!auth.selectedHospital) {
      return;
    }

    const ret = await HospitalService.getAllProfessionals(
      auth.selectedHospital.guid,
    );

    setUsers(ret);
  };

  useEffect(() => {
    fetchUsers();
    setInitialManagers(data?.managers?.map((manager) => manager.guid) ?? []);
  }, []);

  const handleCreateUser = async (data: ResponsibleValues) => {
    await onSubmitResponsible(data);

    toast.success('Profissional cadastrado com sucesso.');
    setIsAddProfessionalVisible(false);
    fetchUsers();
  };

  useEffect(() => {
    setProfessionalData(editData);
  }, [isAddProfessionalVisible]);

  const handleSubmit = async (input: GroupValues) => {
    console.log(input);
    const managersSet = new Set(input.responsibles);

    await Promise.all(
      ínitialManagers
        .filter((manager) => !managersSet.has(manager))
        // eslint-disable-next-line @typescript-eslint/promise-function-async
        .map((manager) =>
          AlertsApi.create({
            title: `Você foi removido de um grupo`,
            message: `Você foi removido do grupo "${data?.groupName}" e não poderá mais acessar suas informações. Se precisar de mais informações, entre em contato com o responsável pelo grupo.`,
            userId: manager,
          }),
        ),
    );
  };
  const validateFormIsComplete = () => {
    if (data) {
      console.log(data);
      if (data?.groupName && data.managers && data.managers.length > 0) {
        if (data && !!data.groupName.length && data.managers) {
          return true;
        }
      }
    }
    return false;
  };

  const validateProfessionalFormIsComplete = () => {
    const name = responsibleMethods.getValues('name');
    const email = responsibleMethods.getValues('email');
    const crm = responsibleMethods.getValues('crm');
    const mainPhone = responsibleMethods.getValues('mainPhone');

    if (name && email && crm && mainPhone) {
      if (
        name.length > 0 &&
        email.length > 0 &&
        crm.length > 0 &&
        mainPhone.length > 0
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    async function searchCep() {
      if (
        professionalData?.cep &&
        professionalData.cep.length === 9 &&
        !professionalData.cep.includes('_')
      ) {
        const cepResult = await CepService.search(professionalData.cep);
        setProfessionalData({
          ...professionalData,
          street: cepResult.logradouro,
          district: cepResult.bairro,
          uf: cepResult.uf,
          city: cepResult.localidade,
        });
      }
    }
    searchCep();
  }, [professionalData?.cep]);

  useEffect(() => {
    setIsSaveEnabled(validateFormIsComplete());
    setIsSaveProfessionalEnabled(validateProfessionalFormIsComplete());
  }, [
    data,
    responsibleMethods.watch('name'),
    responsibleMethods.watch('email'),
    responsibleMethods.watch('mainPhone'),
    responsibleMethods.watch('crm'),
  ]);

  useEffect(() => {
    console.log(data);
    responsibleMethods.setValue(
      ResponsibleFields.HospitalId,
      auth?.selectedHospital?.guid!,
    );
    responsibleMethods.setValue(
      ResponsibleFields.MedicalGroupId,
      auth?.selectedMedicalGroup?.guid,
    );

    groupMethods.setValue(GroupFields.Id, data?.guid);
    groupMethods.setValue(GroupFields.Name, data?.groupName ?? '');
    groupMethods.setValue(
      GroupFields.Responsibles,
      data?.managers?.map((manager) => manager.guid) ?? [],
    );
    groupMethods.setValue(
      GroupFields.HospitalId,
      auth?.selectedHospital?.guid ?? '',
    );
  }, [auth, data]);

  const closeModal = () => {
    setIsAddProfessionalVisible(false);
    responsibleMethods.reset();
  };

  const onClose = () => {
    setIsVisible(false);
    groupMethods.reset();
  };

  return (
    <>
      <div className="add-professional-back">
        <h2>{!data ? 'Criar novo grupo' : 'Atualizar grupo'}</h2>
      </div>
      <div className="header-form">
        <span
          className="sub-title"
          style={{ fontSize: '1rem', marginBottom: '2rem', display: 'block' }}
        >
          Insira as informações necessárias para criar um grupo
        </span>
      </div>
      <FormProvider {...groupMethods}>
        <Form onSubmit={groupMethods.handleSubmit(handleSubmit)}>
          <Grid container rowSpacing={1} columnSpacing={5}>
            <Grid item xs={6}>
              <TextField
                name={GroupFields.Name}
                label="Nome do grupo"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <span style={{ color: 'var(--gray700)' }}>
                Responsável <b style={{ color: 'var(--red)' }}>*</b>
              </span>
              <Autocomplete
                multiple
                value={managers}
                size="small"
                onChange={(event, newValue) => {
                  setManagers(newValue);
                  groupMethods.setValue(
                    GroupFields.Responsibles,
                    newValue.map((manager) => manager.guid),
                  );
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={users}
                getOptionLabel={(option) => option.name}
                sx={{ width: '100%', marginTop: '0.7rem' }}
                renderInput={(params) => <MuiTextField {...params} />}
              />
              {groupMethods.formState.errors.responsibles && (
                <small style={{ color: 'var(--red)', marginTop: '0.2rem' }}>
                  {groupMethods.formState.errors.responsibles.message}
                </small>
              )}
              <p
                style={{
                  color: 'var(--primary',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                }}
                onClick={() => {
                  setIsAddProfessionalVisible(true);
                }}
              >
                <Plus size={12} />
                Adicionar novo responsável
              </p>
            </Grid>
          </Grid>
          <div className="buttons-row">
            <Button onClick={onClose} color="destructive">
              Cancelar
            </Button>
            <Button
              isLoading={isSaving}
              onClick={() => {
                mutateSaveGroup(groupMethods.getValues());
              }}
            >
              Salvar
            </Button>
          </div>
        </Form>
      </FormProvider>

      <Modal
        isVisible={isAddProfessionalVisible}
        setIsVisible={setIsAddProfessionalVisible}
        size={'lg'}
        onClose={closeModal}
      >
        <FormProvider {...responsibleMethods}>
          <Form
            onSubmit={responsibleMethods.handleSubmit(
              handleCreateUser,
              onError,
            )}
          >
            <h2>Dados pessoais</h2>
            <Grid container rowSpacing={1} columnSpacing={5}>
              <Grid item xs={6}>
                <TextField
                  name={ResponsibleFields.Name}
                  label="Nome"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name={ResponsibleFields.Email}
                  label="E-mail"
                  required
                />
              </Grid>
              <Grid item xs={12} style={{ padding: '0px' }}></Grid>

              <Grid item xs={4}>
                <PhoneField
                  name={ResponsibleFields.MainPhone}
                  label="Telefone(1)"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <PhoneField
                  name={ResponsibleFields.SecondaryPhone}
                  label="Telefone(2)"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name={ResponsibleFields.Profession}
                  label="Profissão"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField name={ResponsibleFields.CRM} label="CRM" required />
              </Grid>
              <Grid item xs={2}>
                <SelectField
                  name={ResponsibleFields.CRMState}
                  options={ufsList}
                  label="UF CRM"
                />
              </Grid>
            </Grid>

            <h2>Endereço</h2>
            <Grid container rowSpacing={1} columnSpacing={5}>
              <Grid item xs={2.5}>
                <TextField
                  name={ResponsibleFields.CEP}
                  label="CEP"
                  mask="99999-999"
                />
              </Grid>
              <Grid item xs={5.5}>
                <TextField name={ResponsibleFields.Street} label="Rua" />
              </Grid>
              <Grid item xs={2}>
                <TextField name={ResponsibleFields.Number} label="Número" />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name={ResponsibleFields.Neighborhood}
                  label="Bairro"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name={ResponsibleFields.Complement}
                  label="Complemento"
                />
              </Grid>
              <Grid item xs={2}>
                <SelectField
                  name={ResponsibleFields.UF}
                  label="UF"
                  options={ufsList}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField name={ResponsibleFields.City} label="Cidade" />
              </Grid>
            </Grid>
            <h2>Detalhes</h2>
            <Grid container rowSpacing={1} columnSpacing={5}>
              <Grid item xs={3}>
                <DateField
                  name={ResponsibleFields.AdmissionDate}
                  label="Data de admissão"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField name={ResponsibleFields.Code} label="Código" />
              </Grid>
              <Grid item xs={6}>
                <TextField name={ResponsibleFields.Details} label="Detalhes" />
              </Grid>
            </Grid>
            <div className="buttons-row">
              <Button
                disabled={false}
                onClick={closeModal}
                color="destructive"
                type="button"
              >
                Cancelar
              </Button>
              <Button disabled={!isSaveProfessionalEnabled} type="submit">
                Salvar
              </Button>
            </div>
          </Form>
        </FormProvider>
      </Modal>
    </>
  );
}

/*
<Autocomplete
                        multiple
                        value={data?.managers}
                        size='small'
                        onChange={(event, newValue) => {
                            if (typeof newValue == 'string') {
                                setData({ ...data, 'managers': newValue || undefined });
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.name);
                            const newUser: IUser[] = [{
                                guid: 'add',
                                name: inputValue !== '' && !isExisting ? `Adicionar "${inputValue}"` : 'Adicionar novo profissional',
                                email: "",
                                phone1: null,
                                phone2: null,
                                treatment: 0,
                                crm: null,
                                ufCrm: null,
                                cep: null,
                                street: null,
                                number: null,
                                district: null,
                                complement: null,
                                uf: null,
                                city: null,
                                profession: null,
                                admissionDate: null,
                                code: null,
                                details: null,
                                photoGuid: null,
                                encryptedPassword: null,
                                recoveryCode: null,
                                recoveryTime: null,
                                facebookId: null,
                                googleId: null,
                                appleId: null,
                                authToken: null,
                                createdAt: new Date(),
                                updatedAt: new Date(),
                                isActive: false,
                                inputValue: undefined,
                                title: ""
                            }]

                            const xfiltered = newUser.concat(filtered);

                            return xfiltered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="free-solo-with-text-demo"
                        options={users}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                            return option;
                            }
                            // Regular option
                            return option.name;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        sx={{ width: '100%', marginTop: '0.7rem' }}
                        freeSolo
                        renderInput={(params) => (
                            <MuiTextField {...params}/>
                        )}
                        />
*/
export default AddTurno;
