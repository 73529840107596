import {
  Autocomplete,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
} from '@mui/material';
import { type SetStateAction, useEffect, useState } from 'react';
import Button from '../../../components/button';
import ChalendarResult from '../../../components/chalendarResult';
import Modal from '../../../components/modal';
import './style.css';
import dayjs from '../../../utils/dayjs';

import check from '../../../assets/check.svg';
import addBlue from '../../../assets/add-blue.svg';
import angle from '../../../assets/angle-small.svg';
import { MedicalGroupService } from '../../../api/medicalGroup';
import { useAuth } from '../../../context/AuthContext';
import { type IUser } from '../../../models/IUser';
import { type IShiftDefinition } from '../../../models/IShiftDefinition';
import { ShiftService } from '../../../api/shifts';
import { useLoading } from '../../../context/GlobalLoading';
import { type IShift } from '../../../models/IShift';
import getIconUrl from '../../../utils/icons';
import { formatHour } from '../../../utils/formatDate';
import { ShiftDefinitionService } from '../../../api/shiftDefinition';

interface AddShiftsProps {
  isVisible: boolean;
  setIsVisible: (x: boolean) => void;
  data?: {
    day: number;
    month: number;
    turno: string;
    startHour: string;
    endHour: string;
    year: number;
  };
  setData?: (params: {
    day: number;
    month: number;
    turno: string;
    startHour: string;
    endHour: string;
    year: number;
  }) => void;
  fetchShifts: () => void;
  shifts: IShift[];
}

function AddShift({
  isVisible,
  setIsVisible,
  data,
  fetchShifts,
  shifts,
  setData,
}: AddShiftsProps) {
  let weekDay = -1;
  if (data) {
    weekDay = new Date(data.year, data.month, data.day).getDay();
  }
  const { selectedMedicalGroup, selectedHospital } = useAuth();
  const { setIsGlobalLoading } = useLoading();
  const [value, setValue] = useState<IUser | null>();
  const [turno, setTurno] = useState(data?.turno);
  const [professionals, setProfessionals] = useState<IUser[]>([]);
  const [days, setDays] = useState<number[]>([]);
  const [repeatAways, setRepeatAways] = useState(true);
  const [allTurnos, setAllTurnos] = useState<IShiftDefinition[]>();
  const [conflictDays, setConflictDays] = useState<number[]>([]);
  const [isTurnoOpen, setIsTurnoOpen] = useState(false);
  const [validDays, setValidDays] = useState(false);
  const [fullfilledDays, setFullFilledDays] = useState<number[]>([]);
  const basicWeek = {
    dom: weekDay === 0,
    seg: weekDay === 1,
    ter: weekDay === 2,
    qua: weekDay === 3,
    qui: weekDay === 4,
    sex: weekDay === 5,
    sab: weekDay === 6,
  };
  const [weeks, setWeeks] = useState<
    Array<{
      dom: boolean;
      seg: boolean;
      ter: boolean;
      qua: boolean;
      qui: boolean;
      sex: boolean;
      sab: boolean;
    }>
  >([basicWeek]);

  const daysOfWeek = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ];
  const labelsOfWeek = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];

  const handleSubmit = async () => {
    setIsGlobalLoading(true);

    try {
      const selectedTurno = allTurnos?.find((t) => t.guid === turno);
      if (!selectedTurno) {
        throw new Error('Turno não encontrado');
      }

      const startHour = dayjs(selectedTurno.start, 'HH:mm');
      const endHour = dayjs(selectedTurno.end, 'HH:mm');
      const needsNextDay = endHour.isBefore(startHour);

      const shiftsData = {
        dates: days,
        medicalGroupGuid: selectedMedicalGroup?.guid || '',
        responsibleUserGuid: value?.guid || null,
        shiftDefinitionGuid: selectedTurno.guid,
        month: data?.month || 0,
        year: data?.year || 0,
        needsNextDay,
        startHour: selectedTurno.start,
        endHour: selectedTurno.end,
      };

      await ShiftService.createMany(shiftsData);
      fetchShifts();
      setIsVisible(false);
    } catch (error) {
      console.error('Erro ao criar plantão:', error);
    } finally {
      setIsGlobalLoading(false);
    }
  };

  const handleRepeatAwaysChange = (x: boolean) => {
    setRepeatAways(x);
  };

  const handleWeekChange = (
    i: number,
    day: 'dom' | 'seg' | 'ter' | 'qua' | 'qui' | 'sex' | 'sab',
  ) => {
    const thisWeek = weeks[i];
    thisWeek[day] = !thisWeek[day];
    setWeeks([
      ...weeks.slice(0, i),
      thisWeek,
      ...weeks.slice(i + 1, weeks.length),
    ]);
  };

  useEffect(() => {
    const newDays: number[] = [];
    if (data && repeatAways) {
      const newDate = new Date(data.year, data.month + 1, 0);
      let currentWeek = weeks[0];
      const firstDay = new Date(data.year, data.month, 1).getDay() - 1;
      for (let i = 1; i <= newDate.getDate(); i++) {
        const currentDay = new Date(data.year, data.month, i).getDay();
        if ((firstDay + i) % 7 === 0) {
          currentWeek =
            weeks[
              (firstDay + i) / 7 >= weeks.length
                ? ((firstDay + i) / 7) % weeks.length
                : (firstDay + i) / 7
            ];
        }
        switch (currentDay) {
          case 0:
            if (currentWeek.dom) newDays.push(i);
            break;
          case 1:
            if (currentWeek.seg) newDays.push(i);
            break;
          case 2:
            if (currentWeek.ter) newDays.push(i);
            break;
          case 3:
            if (currentWeek.qua) newDays.push(i);
            break;
          case 4:
            if (currentWeek.qui) newDays.push(i);
            break;
          case 5:
            if (currentWeek.sex) newDays.push(i);
            break;
          case 6:
            if (currentWeek.sab) newDays.push(i);
            break;
        }
      }
    } else if (data) {
      newDays.push(data.day);
    }
    setDays(newDays);
  }, [weeks, repeatAways]);

  useEffect(() => {
    const z = shifts
      .filter((shift) => {
        return value
          ? days.filter(
              (day) =>
                day === new Date(shift.startDate + '').getDate() &&
                new Date(shift.startDate + '').getMonth() === data?.month,
            ).length > 0 && value.guid === shift.responsibleUser?.guid
          : false;
      })
      .map((shift) => new Date(shift.startDate + '').getDate());

    setConflictDays(z.filter((x, i) => z.indexOf(x) === i));
  }, [days, value]);

  useEffect(() => {
    const shiftDefinition = allTurnos?.filter((x) => x.guid === turno).at(0);
    let teste = true;
    const daysFilled: number[] = [];
    days.forEach((day) => {
      const s = shifts.filter(
        (x) =>
          x.shiftDefinitionGuid === turno &&
          day === new Date(x.startDate + '').getDate() &&
          new Date(x.startDate + '').getMonth() === data?.month,
      );
      if (
        !shiftDefinition ||
        (shiftDefinition && s.length >= shiftDefinition.numberOfShifts)
      ) {
        teste = false;
        daysFilled.push(day);
      }
    });
    setValidDays(teste);
    setFullFilledDays(daysFilled);
  }, [days, turno]);

  const fetchProfessionals = async () => {
    let ret: SetStateAction<IUser[]> = [];
    if (selectedMedicalGroup && selectedHospital) {
      ret = await MedicalGroupService.getGroupProfessionals(
        selectedMedicalGroup.guid,
        selectedHospital.guid,
      );
    }
    setProfessionals(ret);
  };

  const fetchTurnos = async () => {
    setAllTurnos(
      selectedMedicalGroup
        ? (await ShiftDefinitionService.listShiftDefinition(
            selectedMedicalGroup?.guid,
          )) || []
        : [],
    );
  };
  useEffect(() => {
    fetchTurnos();
    setTurno(data?.turno);
    fetchProfessionals();
  }, []);

  useEffect(() => {
    fetchProfessionals();
  }, [selectedMedicalGroup]);
  const handleChange = (event: SelectChangeEvent) => {
    setTurno(event.target.value);
  };

  return (
    <Modal isVisible={isVisible} setIsVisible={setIsVisible} size="lg">
      <h2>Adicionar plantão</h2>
      <h3 className="sub-title">
        {
          daysOfWeek[
            new Date(data?.year || 0, data?.month || 0, data?.day || 0).getDay()
          ]
        }
        ,{' '}
        {(data?.day || 0) < 0
          ? new Date(data?.year || 0, data?.month || 0, 0).getDate() +
            (data?.day || 0)
          : data?.day || 0}{' '}
        de{' '}
        {new Date(
          data?.year || 0,
          data?.month || 0,
          data?.day || 0,
        ).toLocaleString('default', { month: 'long' }) + ' '}
        • Equipe: {selectedMedicalGroup?.name}
      </h3>
      <br />
      <span className="label-for-professional">Nome do profissional</span>
      <br />
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={professionals}
        sx={{ width: 300, marginTop: '0.8rem' }}
        value={value}
        size="small"
        popupIcon={<img alt="" src={angle} />}
        onChange={(event: any, newValue: IUser | null) => {
          setValue(newValue);
        }}
        getOptionLabel={(option) => option.name || ''}
        renderInput={(params) => (
          <TextField placeholder="Selecione" {...params} />
        )}
      />
      {conflictDays.length > 0 && (
        <>
          <br />
          <span className="conflict-alert">
            Profissional já possui{' '}
            {conflictDays.length === 1
              ? 'plantão no dia '
              : 'plantões nos dias '}
            {conflictDays.join(', ')}
          </span>
        </>
      )}
      <div className="row">
        <div className="input-field" style={{ marginRight: '2.5rem' }}>
          <label>Período</label>
          <Select
            id="turno"
            open={isTurnoOpen}
            onOpen={() => {
              setIsTurnoOpen(true);
            }}
            onClose={() => {
              setIsTurnoOpen(false);
            }}
            value={turno}
            onChange={handleChange}
            size="small"
            sx={{ width: '7rem' }}
            IconComponent={() => (
              <svg
                onClick={() => {
                  setIsTurnoOpen(true);
                }}
                className="select-styled-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={isTurnoOpen ? { transform: 'rotate(180deg)' } : {}}
              >
                <path
                  d="M18.7098 8.20998C18.6169 8.11625 18.5063 8.04186 18.3844 7.99109C18.2625 7.94032 18.1318 7.91418 17.9998 7.91418C17.8678 7.91418 17.7371 7.94032 17.6152 7.99109C17.4934 8.04186 17.3828 8.11625 17.2898 8.20998L12.7098 12.79C12.6169 12.8837 12.5063 12.9581 12.3844 13.0089C12.2625 13.0596 12.1318 13.0858 11.9998 13.0858C11.8678 13.0858 11.7371 13.0596 11.6152 13.0089C11.4934 12.9581 11.3828 12.8837 11.2898 12.79L6.70982 8.20998C6.61685 8.11625 6.50625 8.04186 6.38439 7.99109C6.26253 7.94032 6.13183 7.91418 5.99982 7.91418C5.8678 7.91418 5.7371 7.94032 5.61524 7.99109C5.49338 8.04186 5.38278 8.11625 5.28982 8.20998C5.10356 8.39734 4.99902 8.6508 4.99902 8.91498C4.99902 9.17917 5.10356 9.43262 5.28982 9.61998L9.87982 14.21C10.4423 14.7718 11.2048 15.0873 11.9998 15.0873C12.7948 15.0873 13.5573 14.7718 14.1198 14.21L18.7098 9.61998C18.8961 9.43262 19.0006 9.17917 19.0006 8.91498C19.0006 8.6508 18.8961 8.39734 18.7098 8.20998Z"
                  fill="#9FABAF"
                />
              </svg>
            )}
          >
            {allTurnos?.map((turno, i) => (
              <MenuItem value={turno.guid}>
                <img
                  className="turno-option"
                  src={getIconUrl(turno.icon)}
                  alt=""
                />
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="input-field">
          <label>Horário</label>
          <div style={{ display: 'flex' }}>
            <TextField
              size="small"
              variant="outlined"
              sx={{ width: '5rem' }}
              value={formatHour(
                allTurnos?.filter((t) => t.guid === turno).at(0)?.start,
              )}
              disabled={true}
            />
            <span className="hour">às</span>
            <TextField
              size="small"
              variant="outlined"
              sx={{ width: '5rem' }}
              value={formatHour(
                allTurnos?.filter((t) => t.guid === turno).at(0)?.end,
              )}
              disabled={true}
            />
          </div>
        </div>
      </div>
      <div className="division"></div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {fullfilledDays.length > 0 && (
            <div className="filled-error">
              As vagas padrões para este período já estão preenchidas
              {(fullfilledDays.length > 1 ? ' nos dias ' : ' no dia ') +
                fullfilledDays.join(', ')}
              !
            </div>
          )}
          <div className="repeat">
            <svg
              width="7"
              height="8"
              viewBox="0 0 7 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 4.00486C7 4.25759 6.92045 4.5113 6.76136 4.71446C6.73153 4.75431 6.59233 4.91859 6.48295 5.02552L6.4233 5.08384C5.58807 5.96938 3.50994 7.30109 2.45597 7.72783C2.45597 7.73755 1.82955 7.99125 1.53125 8H1.49148C1.03409 8 0.606534 7.74824 0.387784 7.33998C0.268466 7.11543 0.159091 6.46416 0.149148 6.45541C0.0596591 5.8712 0 4.97691 0 3.99514C0 2.96574 0.0596591 2.03159 0.169034 1.45808C0.169034 1.44836 0.278409 0.923451 0.348011 0.748481C0.457386 0.496719 0.65625 0.281896 0.90483 0.145808C1.10369 0.0495747 1.3125 0 1.53125 0C1.75994 0.0106926 2.1875 0.155529 2.35653 0.223572C3.47017 0.651276 5.59801 2.05103 6.41335 2.90644C6.55256 3.04253 6.7017 3.20875 6.74148 3.24666C6.91051 3.46051 7 3.72296 7 4.00486Z"
                fill="#009FFA"
              />
            </svg>

            <span
              className={repeatAways ? '' : 'selected'}
              onClick={() => {
                handleRepeatAwaysChange(false);
              }}
            >
              Não repetir
            </span>
            <span
              className={repeatAways ? 'selected' : ''}
              onClick={() => {
                handleRepeatAwaysChange(true);
              }}
            >
              Repetir sempre
            </span>
          </div>
          {repeatAways &&
            weeks.map((week, i) => (
              <div
                key={i}
                className="week-repeat"
                style={{ marginBottom: '0.75rem' }}
              >
                <span
                  className={week.dom ? 'selected' : ''}
                  onClick={() => {
                    handleWeekChange(i, 'dom');
                  }}
                >
                  {week.dom && <img src={check} alt="" />}
                  {labelsOfWeek[0]}
                </span>
                <span
                  className={week.seg ? 'selected' : ''}
                  onClick={() => {
                    handleWeekChange(i, 'seg');
                  }}
                >
                  {week.seg && <img src={check} alt="" />}
                  {labelsOfWeek[1]}
                </span>
                <span
                  className={week.ter ? 'selected' : ''}
                  onClick={() => {
                    handleWeekChange(i, 'ter');
                  }}
                >
                  {week.ter && <img src={check} alt="" />}
                  {labelsOfWeek[2]}
                </span>
                <span
                  className={week.qua ? 'selected' : ''}
                  onClick={() => {
                    handleWeekChange(i, 'qua');
                  }}
                >
                  {week.qua && <img src={check} alt="" />}
                  {labelsOfWeek[3]}
                </span>
                <span
                  className={week.qui ? 'selected' : ''}
                  onClick={() => {
                    handleWeekChange(i, 'qui');
                  }}
                >
                  {week.qui && <img src={check} alt="" />}
                  {labelsOfWeek[4]}
                </span>
                <span
                  className={week.sex ? 'selected' : ''}
                  onClick={() => {
                    handleWeekChange(i, 'sex');
                  }}
                >
                  {week.sex && <img src={check} alt="" />}
                  {labelsOfWeek[5]}
                </span>
                <span
                  className={week.sab ? 'selected' : ''}
                  onClick={() => {
                    handleWeekChange(i, 'sab');
                  }}
                >
                  {week.sab && <img src={check} alt="" />}
                  {labelsOfWeek[6]}
                </span>
              </div>
            ))}
          <div style={{ display: 'flex' }}>
            {weeks.length > 1 && repeatAways && (
              <div
                className="remove-week"
                onClick={() => {
                  setWeeks(weeks.slice(0, weeks.length - 1));
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9998 6C17.8123 5.81253 17.558 5.70721 17.2928 5.70721C17.0277 5.70721 16.7733 5.81253 16.5858 6L11.9998 10.586L7.41382 6C7.22629 5.81253 6.97198 5.70721 6.70682 5.70721C6.44165 5.70721 6.18735 5.81253 5.99982 6C5.81235 6.18753 5.70703 6.44184 5.70703 6.707C5.70703 6.97217 5.81235 7.22647 5.99982 7.414L10.5858 12L5.99982 16.586C5.81235 16.7735 5.70703 17.0278 5.70703 17.293C5.70703 17.5582 5.81235 17.8125 5.99982 18C6.18735 18.1875 6.44165 18.2928 6.70682 18.2928C6.97198 18.2928 7.22629 18.1875 7.41382 18L11.9998 13.414L16.5858 18C16.7733 18.1875 17.0277 18.2928 17.2928 18.2928C17.558 18.2928 17.8123 18.1875 17.9998 18C18.1873 17.8125 18.2926 17.5582 18.2926 17.293C18.2926 17.0278 18.1873 16.7735 17.9998 16.586L13.4138 12L17.9998 7.414C18.1873 7.22647 18.2926 6.97217 18.2926 6.707C18.2926 6.44184 18.1873 6.18753 17.9998 6Z"
                    fill="#737B80"
                  />
                </svg>

                <span>Remover semana</span>
              </div>
            )}
            {repeatAways && weeks.length < 5 && (
              <div
                className="add-week"
                onClick={() => {
                  setWeeks([...weeks, basicWeek]);
                }}
              >
                <img alt="" src={addBlue} /> <span>Adicionar semana</span>
              </div>
            )}
          </div>
        </div>
        <div>
          {data && (
            <ChalendarResult
              month={data.month + 1}
              year={data.year}
              days={days}
              data={data}
              setData={setData}
              setDays={setDays}
            />
          )}
        </div>
      </div>
      <div className="buttons-row">
        <Button
          disabled={false}
          color="destructive"
          onClick={() => {
            setIsVisible(false);
          }}
        >
          Cancelar
        </Button>
        <Button
          disabled={false}
          color="primary"
          onClick={async () => {
            await handleSubmit();
          }}
        >
          Salvar
        </Button>
      </div>
    </Modal>
  );
}

export default AddShift;
