/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Grid, MenuItem, Select } from '@mui/material';
import {
  type ReactElement,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import Button from '../../../components/button';
import Modal from '../../../components/modal';
import './style.css';
import dayjs, { type Dayjs } from '../../../utils/dayjs';
import { useLoading } from '../../../context/GlobalLoading';
import { type IUser } from '../../../models/IUser';
import { useAuth } from '../../../context/AuthContext';
import { MedicalGroupService } from '../../../api/medicalGroup';
import { type IShiftDefinition } from '../../../models/IShiftDefinition';
import { ShiftService } from '../../../api/shifts';
import { type IShift } from '../../../models/IShift';
import getIconUrl from '../../../utils/icons';
import Form from '../../../components/form';
import { FormProvider } from 'react-hook-form';
import useShift from '../hooks/useShift';
import { ShiftFormFields, type ShiftFormValues } from '../forms/shiftForm';
import AutocompleteField from '../../../components/AutocompleteField';
import TimerPickerField from '../../../components/TimePickerField';
import MoneyField from '../../../components/MoneyField';
import TextAreaField from '../../../components/TextAreaField';
import { Info } from 'lucide-react';
import { type ISwap } from '../../../models/ISwap';

interface EditShiftsProps {
  isVisible: boolean;
  setIsVisible: (x: boolean) => void;
  data?: {
    professional: IUser | undefined;
    shiftGuid: string;
    turno: IShiftDefinition;
    date: Date;
    observation: string;
    value: string;
    startDate: Date;
    endDate: Date;
    swap: ISwap | undefined;
  };
  fetchShifts: () => Promise<void>;
  shifts: IShift[];
}
function EditShift({
  isVisible,
  setIsVisible,
  data,
  fetchShifts,
  shifts,
}: EditShiftsProps) {
  const {
    selectedMedicalGroup,
    selectedHospital,
    isAdmin: isManager,
  } = useAuth();
  const { setIsGlobalLoading } = useLoading();
  const [isCoworkersVisible, setIsCoworkersVisible] = useState(false);
  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false);
  const [professionals, setProfessionals] = useState<IUser[]>([]);
  const [coworkers, setCoworkers] = useState<IUser[]>([]);
  const [conflictDays, setConflictDays] = useState<number[]>([]);
  const [hourlyTax, setHourlyTax] = useState<number | undefined>();
  const { methods, onSubmit } = useShift();

  useEffect(() => {
    const professionalId = methods.getValues(ShiftFormFields.Professional);

    const days = shifts
      .filter((shift, i) => {
        const startDate = dayjs(shift.startDate).subtract(3, 'hour');
        const dateToCompare = dayjs(data?.date).subtract(6, 'hour');

        return professionalId
          ? startDate.date() === dateToCompare.date() &&
              startDate.month() === dateToCompare.month() &&
              shift.responsibleUserGuid === professionalId &&
              shift.guid !== data?.shiftGuid
          : false;
      })
      .map((shift) => new Date(shift.startDate + '').getDate());
    setConflictDays(days.filter((x, i) => days.indexOf(x) === i));
  }, [methods.watch(ShiftFormFields.Professional)]);

  const newSetIsVisible = (x: boolean) => {
    setIsVisible(x);
  };

  const fetchCoworkers = async () => {
    let ret: SetStateAction<IUser[]> = [];

    if (data?.shiftGuid) ret = await ShiftService.listCoworkers(data.shiftGuid);
    setCoworkers(ret);
  };

  const fetchProfessionals = async () => {
    let ret: SetStateAction<IUser[]> = [];
    if (selectedMedicalGroup && selectedHospital) {
      ret = await MedicalGroupService.getGroupProfessionals(
        selectedMedicalGroup.guid,
        selectedHospital.guid,
      );
    }
    setProfessionals(ret);
  };

  const formatDay = (x: Date) => {
    const days = [
      'Domingo',
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
    ];
    const month = x.toLocaleString('default', { month: 'long' });

    return `${days[x.getDay()]}, ${x.getDate()} de ${
      month.substring(0, 1).toUpperCase() + month.substring(1)
    }`;
  };

  useEffect(() => {
    fetchProfessionals();
  }, []);

  useEffect(() => {
    fetchProfessionals();
  }, [selectedMedicalGroup]);

  const handleDelete = async () => {
    setIsGlobalLoading(true);

    const shiftsData = {
      shiftGuid: data?.shiftGuid ?? '',
      editAll: false,
      isActive: false,
    };

    ShiftService.update(shiftsData)
      .then(() => {
        setIsConfirmDeleteVisible(false);
        fetchShifts();
        onClose();
        setIsGlobalLoading(false);
      })
      .catch(() => {
        setIsGlobalLoading(false);
      });
  };

  const calcShiftTax = () => {
    if (
      !data?.turno.start ||
      typeof data.turno.start !== 'string' ||
      !data?.turno.end ||
      typeof data.turno.end !== 'string'
    )
      return;

    const startHourIsValid =
      methods.getValues(ShiftFormFields.StartHour)?.length === 5;
    const endHourIsValid =
      methods.getValues(ShiftFormFields.EndHour)?.length === 5;

    if (!startHourIsValid || !endHourIsValid) return;

    const startHour = dayjs(
      methods.getValues(ShiftFormFields.StartHour),
      'HH:mm',
    );

    let endHour = dayjs(methods.getValues(ShiftFormFields.EndHour), 'HH:mm');
    const needsNextDay = endHour.isBefore(startHour);
    endHour = needsNextDay ? endHour.add(1, 'day') : endHour;

    const formDiff = endHour.diff(startHour, 'hour', true);
    const total = (formDiff * (hourlyTax ?? 0)).toFixed(2);

    methods.setValue(ShiftFormFields.Value, String(total));
    methods.setValue(ShiftFormFields.NeedsNextDay, needsNextDay);
  };

  useEffect(calcShiftTax, [
    methods.watch(ShiftFormFields.StartHour),
    methods.watch(ShiftFormFields.EndHour),
  ]);

  const getHourlyTax = (start: Dayjs, end: Dayjs, tax: number) => {
    if (start.isAfter(end)) {
      end = end.add(1, 'day');
    }

    const shiftDiff = end.diff(start, 'hour', true);

    return tax / shiftDiff;
  };

  useEffect(() => {
    setHourlyTax(0);

    if (!data) return;

    fetchCoworkers();

    setHourlyTax(
      getHourlyTax(dayjs(data.startDate), dayjs(data.endDate), +data.value),
    );

    methods.setValue(ShiftFormFields.Professional, data.professional?.guid!);
    methods.setValue(ShiftFormFields.Value, data.value);
    methods.setValue(ShiftFormFields.Observation, data.observation);
    methods.setValue(
      ShiftFormFields.StartHour,
      dayjs(data.startDate).format('HH:mm'),
    );
    methods.setValue(
      ShiftFormFields.EndHour,
      dayjs(data.endDate).format('HH:mm'),
    );
    methods.setValue(ShiftFormFields.ShiftId, data.shiftGuid);
  }, [data, isVisible]);

  const handleSubmit = async (e: any) => {
    try {
      setIsGlobalLoading(true);
      const formData = e as ShiftFormValues;
      const startHour = dayjs(formData.startHour, 'HH:mm');
      const endHour = dayjs(formData.endHour, 'HH:mm');
      const needsNextDay = endHour.isBefore(startHour);

      const updatedData = {
        ...formData,
        needsNextDay,
        startDate:
          dayjs(data?.startDate).format('YYYY-MM-DD') +
          'T' +
          formData.startHour +
          ':00',
        endDate:
          dayjs(
            needsNextDay
              ? dayjs(data?.startDate).add(1, 'day')
              : data?.startDate,
          ).format('YYYY-MM-DD') +
          'T' +
          formData.endHour +
          ':00',
      };

      await onSubmit(dayjs(data?.startDate), updatedData);
      await fetchShifts();
      onClose();
    } catch (error) {
      console.error('Erro ao editar plantão:', error);
    } finally {
      setIsGlobalLoading(false);
    }
  };

  const editAllRelated = methods.watch(
    ShiftFormFields.EditAllRelated,
  ) as boolean;

  const onClose = () => {
    setIsVisible(false);
    methods.reset();
  };

  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={newSetIsVisible}
      size={'lg'}
      onClose={onClose}
    >
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Grid container rowSpacing={1} columnSpacing={5}>
            <Grid item xs={12}>
              <h2>Editar plantão</h2>
              <p>
                {formatDay(data?.date ?? new Date())} • Equipe:{' '}
                {selectedMedicalGroup?.name}
              </p>
              <br />
            </Grid>
            <Grid item xs={4}>
              <AutocompleteField
                name={ShiftFormFields.Professional}
                options={professionals.map((i) => ({
                  label: i.name,
                  id: i.guid,
                }))}
                label="Nome do profissional"
              />
              {conflictDays.length > 0 && (
                <>
                  <br />
                  <span className="conflict-alert">
                    Profissional já possui{' '}
                    {conflictDays.length === 1
                      ? 'plantão no dia '
                      : 'plantões nos dias '}
                    {conflictDays.join(', ')}
                  </span>
                </>
              )}
            </Grid>
            <Grid item xs={1.3}>
              <div className="input-field">
                <label>Período</label>
                <Select
                  value={0}
                  size="small"
                  sx={{ width: '4.2rem' }}
                  disabled
                >
                  <MenuItem value={0}>
                    <img
                      className="turno-option"
                      src={getIconUrl(data?.turno.icon ?? 0)}
                      alt=""
                    />
                  </MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div style={{ display: 'flex' }}>
                  <TimerPickerField
                    name={ShiftFormFields.StartHour}
                    label="Horário"
                  />
                  <span
                    style={{
                      margin: 'auto 1rem',
                      color: 'var(--gray500)',
                      height: '2.5rem',
                      display: 'flex',
                      alignItems: 'end',
                    }}
                  >
                    às
                  </span>
                  <TimerPickerField
                    name={ShiftFormFields.EndHour}
                    label="&nbsp;"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  width: '7.5rem',
                }}
              >
                <MoneyField name={ShiftFormFields.Value} label="Valor" />
              </div>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={6}>
              <TextAreaField
                name={ShiftFormFields.Observation}
                rows={5}
                label="Observação"
              />
            </Grid>
            <Grid item xs={6}>
              <span className="escalados-span">Plantonistas escalados</span>
              <div
                className="escalados"
                onClick={() => {
                  setIsCoworkersVisible(true);
                }}
              >
                {(() => {
                  const c: ReactElement[] = [];
                  if (coworkers?.length > 0)
                    coworkers.slice(0, 5)?.forEach((coworker) => {
                      c.push(
                        <div className="user-escalado">
                          {coworker.name
                            .split(' ')
                            .map((n) => n[0])
                            .slice(0, 2)}
                        </div>,
                      );
                    });

                  if (coworkers?.length > 5)
                    c.push(
                      <div className="user-escalado">
                        {'+' + (coworkers?.length - 5)}
                      </div>,
                    );
                  return c;
                })()}
              </div>
            </Grid>
            <Grid item xs={6}>
              <br />
              <div style={{ display: 'flex' }}>
                <input
                  className="styled-checkbox"
                  type="checkbox"
                  id="editall"
                  checked={editAllRelated}
                  onChange={() => {
                    methods.setValue(
                      ShiftFormFields.EditAllRelated,
                      !editAllRelated,
                    );
                  }}
                />
                <label
                  style={{ margin: 'auto 0', marginLeft: '0.5rem' }}
                  htmlFor="editall"
                >
                  Editar todos os plantões relacionados
                </label>
              </div>
              <br />
              {editAllRelated && (
                <div
                  className="group-alert"
                  style={{
                    padding: '0.75rem 1rem',
                    display: 'flex',
                    gap: '.4rem',
                  }}
                >
                  <Info size={16} />
                  <span>Só serão editados plantões criados juntos</span>
                </div>
              )}
            </Grid>
            {!isManager && (
              <div
                className="buttons-row"
                style={{ paddingTop: editAllRelated ? '0rem' : '3rem' }}
              >
                <Button
                  disabled={false}
                  color="destructive"
                  type="button"
                  onClick={onClose}
                >
                  Cancelar
                </Button>
                <div>
                  <button
                    className={'styled-button destructive'}
                    style={{ width: 'auto' }}
                    type="button"
                    onClick={() => {
                      setIsConfirmDeleteVisible(true);
                    }}
                  >
                    Excluir Plantão
                  </button>
                  <Button disabled={false} color="primary" type="submit">
                    Salvar
                  </Button>
                </div>
              </div>
            )}
          </Grid>
        </Form>
      </FormProvider>
      <Modal
        isVisible={isConfirmDeleteVisible}
        setIsVisible={setIsConfirmDeleteVisible}
        size={'xsm'}
      >
        <h2>Excluir plantão</h2>
        <p>
          Você tem certeza que deseja excluir este plantão?
          <br />
          Esta ação não poderá ser desfeita.
        </p>
        <div className="buttons-row">
          <Button
            disabled={false}
            type="button"
            color="primary"
            onClick={() => {
              setIsConfirmDeleteVisible(false);
            }}
          >
            Voltar
          </Button>
          <button
            className={'styled-button destructive'}
            type="button"
            style={{ width: 'auto' }}
            onClick={async () => {
              await handleDelete();
              methods.reset();
            }}
          >
            Apagar Plantão
          </button>
        </div>
      </Modal>
      <Modal
        isVisible={isCoworkersVisible}
        setIsVisible={setIsCoworkersVisible}
        size={'xxsm'}
      >
        <h2>Plantonistas escalados</h2>
        <div className="coworkers-list">
          {(() => {
            const c: ReactElement[] = [];
            coworkers?.forEach((coworker) => {
              c.push(
                <div className="coworker">
                  <div className="img">
                    {coworker.name
                      .split(' ')
                      .map((n) => n[0])
                      .join('')}
                  </div>
                  <div className="info">
                    <span className="name">
                      {(coworker.shortTreatment === 'Prefiro não informar'
                        ? ''
                        : coworker.shortTreatment) +
                        ' ' +
                        coworker.name}{' '}
                    </span>
                    <span className="details">
                      {coworker.specialties
                        ?.map((specialty) => specialty.title + ', ')
                        .join('')
                        .slice(0, -2) +
                        ' - ' +
                        selectedMedicalGroup?.name}
                    </span>
                  </div>
                </div>,
              );
            });

            return c;
          })()}
        </div>
      </Modal>
    </Modal>
  );
}

export default EditShift;
