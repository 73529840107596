/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { type ReactElement, type ReactNode } from 'react';
import './style.css';

interface ButtonProps {
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  color?:
    | 'primary'
    | 'secondary'
    | 'destructive'
    | 'default'
    | 'default-danger'
    | undefined;
  onClick?: () => void;
  children: ReactNode | ReactElement;
  isLoading?: boolean;
}
function Button({
  disabled,
  type,
  color,
  onClick,
  children,
  isLoading = false,
}: ButtonProps) {
  return (
    <>
      <button
        className={`styled-button ${disabled ? 'disabled' : ''} ${color ?? 'primary'}`}
        disabled={disabled || isLoading}
        type={type ?? 'button'}
        onClick={() => {
          onClick && onClick();
        }}
      >
        {isLoading ? 'Carregando...' : children}
      </button>
    </>
  );
}

export default Button;
