/* eslint-disable react-hooks/exhaustive-deps */
import Container from '../../components/container';
import arrow from '../../assets/arrow.svg';
import dotMenu from '../../assets/dotsmenu.svg';
import './style.css';
// import Button from '../../components/button';
import Schedule from '../../components/schedule';
import { ShiftService } from '../../api/shifts';
import { useState, useEffect } from 'react';
import { type IShift } from '../../models/IShift';
import { type IShiftDefinition } from '../../models/IShiftDefinition';
import { useAuth } from '../../context/AuthContext';
import AddShift from './addShift';
import Button from '../../components/button';
import PublishShifts from './publishShifts';
import { Divider, Menu, MenuItem, Button as MuiButton } from '@mui/material';
import EditShift from './editShift';
import { type IUser } from '../../models/IUser';
import ExportShifts from './exportShifts';
import { ShiftDefinitionService } from '../../api/shiftDefinition';
import ExportShiftsConsolidated from './exportShiftsConsolidate';
import ExportSchedule from './exportSchedule';
import Modal from '../../components/modal';
import { toast } from 'react-toastify';
import { type ISwap } from '../../models/ISwap';
import { DuplicateWeekModal } from './modals/dulicate-week-modal';
import dayjs from 'dayjs';

function Shifts() {
  const [shifts, setShifts] = useState<IShift[]>([]);
  const [date, setDate] = useState(new Date());
  const [dateMonth, setDateMonth] = useState<string>();
  const [duplicationData, setDuplicationData] = useState<{
    sourceMonth: number;
    destinationMonth: number;
    sourceYear: number;
    destinationYear: number;
  }>({
    sourceMonth: 0,
    destinationMonth: 0,
    sourceYear: 0,
    destinationYear: 0,
  });
  const [dateYear, setDateYear] = useState<string>();
  const [shiftDefinitions, setShiftDefinitions] = useState<IShiftDefinition[]>(
    [],
  );
  const [isDuplicateVisible, setIsDuplicateVisible] = useState(false);
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [addData, setAddData] = useState<{
    day: number;
    month: number;
    turno: string;
    startHour: string;
    endHour: string;
    year: number;
  }>();
  const [editData, setEditData] = useState<{
    professional: IUser | undefined;
    shiftGuid: string;
    turno: IShiftDefinition;
    date: Date;
    observation: string;
    value: string;
    startDate: Date;
    endDate: Date;
    swap: ISwap | undefined;
  }>();
  const [isPublishVisible, setIsPublishVisible] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [publishButton, setPublishButton] = useState(false);
  const { selectedMedicalGroup, isAdmin: isManager } = useAuth();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddShift = (
    day: number,
    month: number,
    turno: string,
    startHour: string,
    endHour: string,
    year: number,
  ) => {
    if (day !== 0 && new Date(year, month + 1, 0).getDate() >= day) {
      setAddData({
        day,
        month,
        turno,
        startHour,
        endHour,
        year,
      });
      setIsAddVisible(true);
    }
  };

  // const handleDuplicate = async () => {
  //   await ShiftService.duplicateWeeks({
  //     medicalGroupGuid: selectedMedicalGroup?.guid || '',
  //     currentMonth: ,
  //     currentYear: date.getFullYear(),
  //   });
  //   toast.success('Duplicação realizada com sucesso.');
  //   fetchShifts();
  //   setIsDuplicateVisible(false);
  // };

  //    setTimeout(() => {fetchShifts()}, 100000);

  const handleEditShift = (
    professional: IUser | undefined,
    shiftGuid: string,
    turno: IShiftDefinition,
    dateToEdit: Date,
    observation: string,
    value: string,
    startDate: Date,
    endDate: Date,
    swap: ISwap | undefined,
  ) => {
    if (date.getMonth() === dateToEdit.getMonth()) {
      console.log(`Valor do Shift: ${value}`);

      setEditData({
        professional,
        shiftGuid,
        turno,
        date: dateToEdit,
        observation,
        value,
        startDate,
        endDate,
        swap,
      });
      setIsEditVisible(true);
    }
  };

  const changeStartAndEnd = (currentDate: Date) => {
    let firstDay =
      new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate() -
      new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

    if (
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1,
      ).getDay() !== 0
    ) {
      firstDay++;
    }

    const lastDay =
      7 -
      ((new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1,
      ).getDay() +
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0,
        ).getDate()) %
        7);

    const endDateMonth =
      currentDate.getMonth() + 2 > 12 ? 1 : currentDate.getMonth() + 2;

    const newStartAndEnd = {
      startDate: {
        day: firstDay,
        month: currentDate.getMonth() === 0 ? 12 : currentDate.getMonth(),
      },
      endDate: {
        day:
          lastDay > 0 && lastDay !== 7
            ? lastDay
            : new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                0,
              ).getDate(),
        month:
          lastDay > 0 && lastDay !== 7
            ? endDateMonth
            : currentDate.getMonth() + 1,
      },
    };

    return newStartAndEnd;
  };

  const [startAndEndDay, setStartAndEndDay] = useState(
    changeStartAndEnd(new Date()),
  );

  const auth = useAuth();

  const mountShiftsFilters = () => {
    const { startDate, endDate } = startAndEndDay;
    const endYear =
      startDate.month + 2 > 12 && startDate.month <= date.getMonth() + 1
        ? date.getFullYear() + 1
        : date.getFullYear();
    const startYear =
      startDate.month > 11 && date.getFullYear() === endYear
        ? date.getFullYear() - 1
        : date.getFullYear();
    const startDateString = `${startDate.month}-${startDate.day}-${startYear}`;
    const endDateString = `${endDate.month}-${endDate.day}-${endYear}`;

    return {
      startDate: startDateString,
      endDate: endDateString,
    };
  };

  const fetchShifts = async () => {
    const { startDate, endDate } = mountShiftsFilters();

    if (startAndEndDay.startDate.month !== 0 && selectedMedicalGroup) {
      const ret = await ShiftService.getAll({
        startDate,
        endDate,
        medicalGroupGuid: selectedMedicalGroup.guid,
      });

      setShifts(ret.results.shifts);
    }
  };

  useEffect(() => {
    fetchShifts();
  }, [startAndEndDay, selectedMedicalGroup]);

  useEffect(() => {
    fetchShiftDefinitions();
  }, [auth.selectedMedicalGroup, auth.user]);

  const fetchShiftDefinitions = async () => {
    if (auth.selectedMedicalGroup) {
      setShiftDefinitions(
        await ShiftDefinitionService.listShiftDefinition(
          auth.selectedMedicalGroup.guid,
        ),
      );
    }
  };

  const handleDateChange = (x: number) => {
    const newDate = dayjs(date).add(x, 'month').toDate();
    const month = newDate.toLocaleString('pt-BR', { month: 'long' });
    setDateMonth(month.substring(0, 1).toUpperCase() + month.substring(1));
    setDateYear(newDate.getFullYear().toString());
    const nextMonth = dayjs(newDate).add(1, 'month').toDate();
    setDuplicationData({
      sourceMonth: dayjs(newDate).add(1, 'month').get('month'),
      destinationMonth: dayjs(nextMonth).add(1, 'month').get('month'),
      sourceYear: dayjs(newDate).add(1, 'month').get('year'),
      destinationYear: dayjs(nextMonth).add(1, 'month').get('year'),
    });
    setDate(newDate);
    setStartAndEndDay(changeStartAndEnd(newDate));
  };

  useEffect(() => {
    setStartAndEndDay(changeStartAndEnd(new Date()));
    fetchShifts();
    fetchShiftDefinitions();
    handleDateChange(0);
  }, []);

  return (
    <>
      {isAddVisible && (
        <AddShift
          isVisible={isAddVisible}
          setIsVisible={setIsAddVisible}
          data={addData}
          fetchShifts={fetchShifts}
          shifts={shifts}
          setData={setAddData}
        />
      )}
      <Container>
        <div className="header-container">
          <div className="header">
            <span>Escalas</span>
          </div>

          <div className="month-picker">
            <div
              className="arrow"
              onClick={() => {
                handleDateChange(-1);
              }}
            >
              <img src={arrow} alt="" />
            </div>
            <div
              className="arrow right-arrow"
              onClick={() => {
                handleDateChange(1);
              }}
            >
              <img src={arrow} alt="" />
            </div>
            <span className="month">{dateMonth + ' ' + dateYear}</span>
            <MuiButton
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <div className="dot-menu">
                <img src={dotMenu} alt="" />
              </div>
            </MuiButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleClose}>
                <span
                  onClick={() => {
                    if (!selectedMedicalGroup) {
                      toast.error('Nenhum grupo médico selecionado!');
                    } else {
                      setIsDuplicateVisible(true);
                    }
                  }}
                >
                  Duplicar semanas
                </span>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleClose}>
                <ExportShifts
                  shiftsParam={shifts}
                  date={date}
                  hospitalName={auth.selectedMedicalGroup?.hospital?.name || ''}
                  medicalGroup={auth.selectedMedicalGroup}
                />
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ExportShiftsConsolidated
                  shifts={shifts}
                  date={date}
                  hospitalName={auth.selectedMedicalGroup?.hospital?.name || ''}
                  medicalGroup={auth.selectedMedicalGroup}
                />
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ExportSchedule
                  shifts={shifts}
                  shiftDefinitions={shiftDefinitions}
                  date={date}
                  hospitalName={auth.selectedMedicalGroup?.hospital?.name || ''}
                  medicalGroup={auth.selectedMedicalGroup}
                />
              </MenuItem>
            </Menu>
          </div>
        </div>
      </Container>
      <div className="schedule-container">
        <Container>
          {/*

                    {/*<span>Agosto - Escala não publicada</span> */}
          {!isManager && (
            <div>
              <div className="header" style={{ justifyContent: 'end' }}>
                <span></span>
                <div className="actions" style={{ marginBottom: '0.5rem' }}>
                  <Button
                    disabled={false}
                    onClick={() => {
                      if (dateYear && shiftDefinitions[0]) {
                        handleAddShift(
                          1,
                          date.getMonth(),
                          shiftDefinitions[0].guid,
                          shiftDefinitions[0].start,
                          shiftDefinitions[0].end,
                          parseInt(dateYear),
                        );
                      }
                    }}
                    color={'secondary'}
                  >
                    Adicionar Plantão
                  </Button>
                </div>
                <div className="actions" style={{ marginBottom: '0.5rem' }}>
                  <Button
                    disabled={false}
                    onClick={() => {
                      setIsPublishVisible(true);
                    }}
                  >
                    Publicar
                  </Button>
                </div>
              </div>
            </div>
          )}

          {isEditVisible && (
            <EditShift
              setIsVisible={setIsEditVisible}
              isVisible={isEditVisible}
              data={editData}
              fetchShifts={fetchShifts}
              shifts={shifts}
            />
          )}
          {/* <span className='week-number'>1º semana</span> */}
          {
            <Schedule
              shifts={shifts}
              date={date}
              definitions={shiftDefinitions}
              setIsAddVisible={setIsAddVisible}
              handleAddFunction={handleAddShift}
              setPublishVisible={setPublishButton}
              handleEditShift={handleEditShift}
            />
          }
          <PublishShifts
            isVisible={isPublishVisible}
            setIsVisible={setIsPublishVisible}
            month={date.getMonth()}
            year={date.getFullYear()}
            fetchShifts={fetchShifts}
          />
          <DuplicateWeekModal
            monthOrigin={duplicationData.sourceMonth}
            yearOrigin={duplicationData.sourceYear}
            monthDestination={duplicationData.destinationMonth}
            yearDestination={duplicationData.destinationYear}
            isModalVisible={isDuplicateVisible}
            setVisibility={setIsDuplicateVisible}
            medicalGroupGuid={selectedMedicalGroup?.guid}
          />
        </Container>
      </div>
    </>
  );
}

export default Shifts;
