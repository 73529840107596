import { useState, type HTMLProps, type KeyboardEvent } from 'react';
import { Content, Error, Input, Label, Wrapper } from './styles';
import { useFormContext } from 'react-hook-form';

export default function MoneyField({
  label,
  required,
  ...props
}: MoneyFieldProps) {
  const methods = useFormContext();
  const error = methods.formState.errors[props.name];
  const id = props.name;
  const [focused, setFocused] = useState(false);
  let inputValue: any;

  const onFocus = () => {
    setFocused(true);
  };
  const onBlur = () => {
    setFocused(false);
  };

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!(e.target instanceof HTMLInputElement)) return;
    if (e.target.type !== 'number') return;
    if (
      e.metaKey ||
      e.ctrlKey ||
      e.key === 'Backspace' ||
      e.key === 'Delete' ||
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowRight' ||
      e.key === 'ArrowUp' ||
      e.key === 'ArrowDown' ||
      e.key === ',' ||
      e.target.value === ''
    ) {
      return;
    }

    // Permitir números
    if (!/^\d+(\.\d{1,2})?$/.test(e.target.value)) {
      e.target.value = inputValue;
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!(e.target instanceof HTMLInputElement)) return;
    if (e.target.type !== 'number') return;
    if (
      e.metaKey ||
      e.ctrlKey ||
      e.key === 'Backspace' ||
      e.key === 'Delete' ||
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowRight' ||
      e.key === 'ArrowUp' ||
      e.key === 'ArrowDown' ||
      e.key === ','
    ) {
      return;
    }

    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }

    if (/^\d+(\.\d{1,2})?$/.test(e.target.value)) {
      inputValue = e.target.value;
    }
  };

  return (
    <Content>
      {label && (
        <Label htmlFor={id}>
          {label} {required && <b>*</b>}
        </Label>
      )}
      <Wrapper htmlFor={id} focused={focused}>
        <span>R$</span>
        <Input
          type="number"
          {...props}
          id={id}
          step="0.01"
          {...methods.register(props.name)}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
        />
      </Wrapper>
      {error?.message && typeof error.message === 'string' && (
        <Error>{error.message}</Error>
      )}
    </Content>
  );
}

interface MoneyFieldProps extends HTMLProps<HTMLInputElement> {
  name: string;
  label?: string;
}
