import { useEffect, useState } from 'react';
import Button from '../../components/button';
import Container from '../../components/container';
import Table from '../../components/table';
import AddProfessional, { type addFields } from './add';
import editIcon from '../../assets/edit.svg';
import trashIcon from '../../assets/trash.svg';
import addIcon from '../../assets/add.svg';
import './style.css';
import Modal from '../../components/modal';
import { useAuth } from '../../context/AuthContext';
import { toast } from 'react-toastify';
import { type IShiftDefinition } from '../../models/IShiftDefinition';
import getIconUrl from '../../utils/icons';
import { ShiftDefinitionService } from '../../api/shiftDefinition';
import dayjs from 'dayjs';
import { useMutation } from '@tanstack/react-query';

function Turnos() {
  const [turnos, setTurnos] = useState<IShiftDefinition[]>();
  const [isAddVisible, setIsAddVisible] = useState(false);
  const defaultEditData: addFields = {
    icon: 1,
  };
  const [editData, setEditData] = useState<addFields | undefined>(
    defaultEditData,
  );
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [turnoToRemove, setTurnoToRemove] = useState<
    IShiftDefinition | undefined
  >();
  const { selectedMedicalGroup } = useAuth();

  const fetchTurnos = async () => {
    if (selectedMedicalGroup) {
      const ret = await ShiftDefinitionService.listShiftDefinition(
        selectedMedicalGroup.guid,
      );
      setTurnos(ret);
    }
  };

  const { isPending: isDeleting, mutate: mutateDeleteTurno } = useMutation({
    mutationFn: async () => {
      if (turnoToRemove && selectedMedicalGroup) {
        await ShiftDefinitionService.deleteShiftDefinition(turnoToRemove.guid);
      }
    },
    onSuccess() {
      onSuccess();
      toast.success('Turno removido com sucesso.');
    },
    onError() {
      toast.error('Erro ao deletar o turno');
    },
  });

  // const removeTurno = async () => {
  //   if (turnoToRemove && selectedMedicalGroup) {
  //     await ShiftDefinitionService.deleteShiftDefinition(turnoToRemove.guid);
  //     onSuccess();
  //     toast.success('Turno removido com sucesso.');
  //   }
  // };

  const handleEditButton = (turno: IShiftDefinition) => {
    setEditData({
      guid: turno.guid,
      icon: turno.icon,
      turnoName: turno.name,
      start: turno.start,
      end: turno.end,
      numberOfShifts: turno.numberOfShifts,
      segValue: turno.segValue,
      terValue: turno.terValue,
      quaValue: turno.quaValue,
      quiValue: turno.quiValue,
      sexValue: turno.sexValue,
      sabValue: turno.sabValue,
      domValue: turno.domValue,
    });

    setIsAddVisible(true);
  };

  useEffect(() => {
    fetchTurnos();
  }, []);

  useEffect(() => {
    fetchTurnos();
  }, [selectedMedicalGroup]);

  const onSuccess = () => {
    fetchTurnos();
    setIsAddVisible(false);
    setIsDeleteVisible(false);
  };

  const formatedValue = (value: any) => {
    return `R$ ${value}`.includes('.')
      ? `R$ ${value}`.replace('.', ',')
      : `R$ ${value},00`;
  };

  const shiftValue = (turno: IShiftDefinition) => {
    let lowest = turno.segValue;
    let biggest = turno.segValue;

    if (turno.terValue > biggest) {
      biggest = turno.terValue;
    }

    if (turno.quaValue > biggest) {
      biggest = turno.quaValue;
    }

    if (turno.quiValue > biggest) {
      biggest = turno.quiValue;
    }

    if (turno.sexValue > biggest) {
      biggest = turno.sexValue;
    }

    if (turno.sabValue > biggest) {
      biggest = turno.sabValue;
    }

    if (turno.domValue > biggest) {
      biggest = turno.domValue;
    }

    if (turno.terValue < lowest) {
      lowest = turno.terValue;
    }

    if (turno.quaValue < lowest) {
      lowest = turno.quaValue;
    }

    if (turno.quiValue < lowest) {
      lowest = turno.quiValue;
    }

    if (turno.sexValue < lowest) {
      lowest = turno.sexValue;
    }

    if (turno.sabValue < lowest) {
      lowest = turno.sabValue;
    }

    if (turno.domValue < lowest) {
      lowest = turno.domValue;
    }

    if (lowest === biggest) {
      return formatedValue(lowest);
    } else {
      return `${formatedValue(lowest)} - ${formatedValue(biggest)}`;
    }
  };
  return (
    <>
      <Container>
        {turnos && turnos.length > 0 ? (
          <>
            <div className="header">
              <div>
                <span>Turnos</span> <br />
                <br />
                <span className="sub-title">
                  {turnos?.length}{' '}
                  {turnos.length === 1 ? 'registro' : 'registros'}
                </span>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '12rem' }}>
                  <Button
                    onClick={() => {
                      setEditData(defaultEditData);
                      setIsAddVisible(true);
                    }}
                    disabled={!selectedMedicalGroup}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: '1rem' }}
                    >
                      <path
                        d="M11 5H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H5V11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12C6.26522 12 6.51957 11.8946 6.70711 11.7071C6.89464 11.5196 7 11.2652 7 11V7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5Z"
                        fill="white"
                      />
                    </svg>
                    Adicionar turno
                  </Button>
                </div>
              </div>
            </div>

            <Table>
              <thead>
                <tr>
                  <th>PERÍODO</th>
                  <th>ÍCONE</th>
                  <th>HORA INÍCIO</th>
                  <th>HORA FIM</th>
                  <th>VALOR PERÍODO</th>
                  <th style={{ width: '7rem' }}></th>
                </tr>
              </thead>
              <tbody>
                {turnos
                  .sort((a, b) => parseInt(a.start) - parseInt(b.start))
                  ?.map((turno, i) => {
                    return (
                      <tr key={i}>
                        <td>{turno.name}</td>
                        <td>
                          <img src={getIconUrl(turno.icon)} alt="" />
                        </td>
                        <td>{dayjs(turno.start, 'HH:mm').format('HH:mm')}</td>
                        <td>{dayjs(turno.end, 'HH:mm').format('HH:mm')}</td>
                        <td>{shiftValue(turno)}</td>
                        <td className="actions">
                          <button
                            className="edit-button"
                            onClick={() => {
                              handleEditButton(turno);
                            }}
                          >
                            <img src={editIcon} alt="" />
                          </button>
                          <button
                            className="delete-button"
                            onClick={() => {
                              setTurnoToRemove(turno);
                              setIsDeleteVisible(true);
                            }}
                          >
                            <img src={trashIcon} alt="" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </>
        ) : (
          <>
            <div className="header">
              <div>
                <span>Turnos</span> <br />
                <br />
                <span className="sub-title">
                  {turnos?.length ?? 0} registros
                </span>
              </div>
            </div>
            <div className="default-message">
              <h2>Solicitação de criação de turno pendente.</h2>
              <div
                style={{
                  width: '15rem',
                  textAlign: 'center',
                  margin: 'auto',
                  marginTop: '2rem',
                }}
              >
                <Button
                  disabled={false}
                  color="primary"
                  onClick={() => {
                    setIsAddVisible(true);
                  }}
                >
                  <img src={addIcon} alt="" style={{ marginRight: '1rem' }} />
                  Criar turno
                </Button>
              </div>
            </div>
          </>
        )}
      </Container>
      {isAddVisible && (
        <Modal
          setIsVisible={setIsAddVisible}
          isVisible={isAddVisible}
          size="xmd"
        >
          <AddProfessional
            setIsVisible={setIsAddVisible}
            editData={editData}
            setEditData={setEditData}
            onSuccess={onSuccess}
            isVisible={isAddVisible}
          />
        </Modal>
      )}
      <Modal
        isVisible={isDeleteVisible}
        setIsVisible={setIsDeleteVisible}
        size="md"
      >
        <h2>Excluir Turno</h2>
        <h3 className="sub-title">
          Tem certeza que deseja excluir este turno? Ele não aparecerá mais para
          nenhum profissional.
        </h3>
        <div className="buttons-row" style={{ paddingTop: '1rem' }}>
          <Button
            disabled={false}
            color="destructive"
            onClick={() => {
              setIsDeleteVisible(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            isLoading={isDeleting}
            color="primary"
            onClick={() => {
              mutateDeleteTurno();
            }}
          >
            Excluir
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default Turnos;
