/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Form from '../../../components/form';
import Grid from '@mui/material/Grid';
import './style.css';
import Button from '../../../components/button';
import { useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import { useAuth } from '../../../context/AuthContext';
import { toast } from 'react-toastify';
import { type IHospital } from '../../../models/IHospital';
import getIconUrl from '../../../utils/icons';
import addIcon from '../../../assets/blueAdd.svg';
import useTurno from '../hooks/useTurno';
import { FormProvider } from 'react-hook-form';
import TextField from '../../../components/TextField';
import { TurnoFields, type TurnoValues } from '../forms/turnoForm';
import TimerPickerField from '../../../components/TimePickerField';
import MoneyField from '../../../components/MoneyField';
import { type IShiftDefinition } from '../../../models/IShiftDefinition';
import { useMutation } from '@tanstack/react-query';

export interface addFields {
  guid?: string;
  start?: string;
  end?: string;
  icon?: number;
  numberOfShifts?: number;
  segValue?: number;
  terValue?: number;
  quaValue?: number;
  quiValue?: number;
  sexValue?: number;
  sabValue?: number;
  domValue?: number;
  turnoName?: string;
}

interface AddTurnoProps {
  editData?: addFields;
  setEditData: (x: addFields) => void;
  setIsVisible: (x: boolean) => void;
  isVisible: boolean;
  onSuccess: (data?: IShiftDefinition) => void;
}

function AddTurno({
  editData,
  setEditData,
  setIsVisible,
  onSuccess,
  isVisible,
}: AddTurnoProps) {
  const [data] = useState<addFields | undefined>(editData);
  const { selectedMedicalGroup } = useAuth();
  const hospital: IHospital | undefined = selectedMedicalGroup?.hospital;
  const [page, setPage] = useState(1);
  const [isTurnoOpen, setIsTurnoOpen] = useState(false);
  const { methods, onSubmit, onError, reset } = useTurno();

  const { isPending: isSaving, mutate: mutateSaveTurno } = useMutation({
    mutationFn: async (data: TurnoValues) => {
      await onSubmit(data);
    },
    onSuccess() {
      onSuccess();
    },

    onError() {
      toast.error('Erro ao salvar turno');
    },
  });

  // const handleSubmit = async (data: TurnoValues) => {
  //   try {
  //     await onSubmit(data);
  //     onSuccess();
  //   } catch {
  //     toast.error('Erro ao salvar turno');
  //   }
  // };

  useEffect(() => {
    methods.setValue(
      TurnoFields.MedicalGroupId,
      selectedMedicalGroup?.guid ?? '',
    );

    if (!data || (data && Object.keys(data).length === 1)) return;

    methods.setValue(TurnoFields.WorkTime, data.turnoName!);
    methods.setValue(TurnoFields.StartDate, data.start!);
    methods.setValue(TurnoFields.EndDate, data.end!);
    methods.setValue(TurnoFields.Icon, data.icon!);
    methods.setValue(TurnoFields.Unique, false);
    methods.setValue(TurnoFields.MondayValue, String(data.segValue));
    methods.setValue(TurnoFields.TuesdayValue, String(data.terValue));
    methods.setValue(TurnoFields.WednesdayValue, String(data.quaValue));
    methods.setValue(TurnoFields.ThursdayValue, String(data.quiValue));
    methods.setValue(TurnoFields.FridayValue, String(data.sexValue));
    methods.setValue(TurnoFields.SaturdayValue, String(data.sabValue));
    methods.setValue(TurnoFields.SundayValue, String(data.domValue));
    methods.setValue(TurnoFields.NumberOfShifts, String(data.numberOfShifts));
    methods.setValue(TurnoFields.TurnoId, data.guid!);
  }, [data]);

  const onCancel = () => {
    setIsVisible(false);
    reset();
  };

  const isUnique = methods.watch(TurnoFields.Unique);
  const numberOfShifts = methods.watch(TurnoFields.NumberOfShifts);

  return (
    <>
      <div className="add-professional-back">
        <h2>{editData ? 'Adicionar turno' : 'Atualizar turno'}</h2>
      </div>
      <div className="header-form">
        <span className="sub-title" style={{ fontSize: '1rem' }}>
          {hospital?.name}
        </span>
        <br />
        <br />
        <br />
        <br />
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onError)}>
          {page === 1 && (
            <>
              <span style={{ fontSize: '1rem' }}>
                Insira as informações necessárias para adicionar um turno
              </span>
              <br />
              <br />
              <br />
              <Grid container rowSpacing={1} columnSpacing={5}>
                <Grid item xs={4.4}>
                  <TextField
                    name={TurnoFields.WorkTime}
                    placeholder="Período"
                  />
                </Grid>
                <Grid item xs={2.5}>
                  <TimerPickerField name={TurnoFields.StartDate} />
                </Grid>
                <Grid item xs={0.8}>
                  <div
                    className="styled-input-container"
                    style={{
                      display: 'flex',
                      height: '2.2rem',
                      flexDirection: 'column',
                    }}
                  >
                    <span
                      style={{
                        margin: 'auto',
                        color: 'var(--gray500)',
                        textAlign: 'center',
                      }}
                    >
                      às
                    </span>
                  </div>
                </Grid>
                <Grid item xs={2.5}>
                  <TimerPickerField name={TurnoFields.EndDate} />
                </Grid>
                <Grid item xs={1.8}>
                  <Select
                    name="icon"
                    value={methods.getValues(TurnoFields.Icon)}
                    onChange={(e) => {
                      methods.setValue(TurnoFields.Icon, +e.target.value);
                    }}
                    size="small"
                    sx={{ width: '100%' }}
                    open={isTurnoOpen}
                    onOpen={() => {
                      setIsTurnoOpen(true);
                    }}
                    onClose={() => {
                      setIsTurnoOpen(false);
                    }}
                    IconComponent={() => (
                      <svg
                        onClick={() => {
                          setIsTurnoOpen(true);
                        }}
                        className="select-styled-icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={
                          isTurnoOpen ? { transform: 'rotate(180deg)' } : {}
                        }
                      >
                        <path
                          d="M18.7098 8.20998C18.6169 8.11625 18.5063 8.04186 18.3844 7.99109C18.2625 7.94032 18.1318 7.91418 17.9998 7.91418C17.8678 7.91418 17.7371 7.94032 17.6152 7.99109C17.4934 8.04186 17.3828 8.11625 17.2898 8.20998L12.7098 12.79C12.6169 12.8837 12.5063 12.9581 12.3844 13.0089C12.2625 13.0596 12.1318 13.0858 11.9998 13.0858C11.8678 13.0858 11.7371 13.0596 11.6152 13.0089C11.4934 12.9581 11.3828 12.8837 11.2898 12.79L6.70982 8.20998C6.61685 8.11625 6.50625 8.04186 6.38439 7.99109C6.26253 7.94032 6.13183 7.91418 5.99982 7.91418C5.8678 7.91418 5.7371 7.94032 5.61524 7.99109C5.49338 8.04186 5.38278 8.11625 5.28982 8.20998C5.10356 8.39734 4.99902 8.6508 4.99902 8.91498C4.99902 9.17917 5.10356 9.43262 5.28982 9.61998L9.87982 14.21C10.4423 14.7718 11.2048 15.0873 11.9998 15.0873C12.7948 15.0873 13.5573 14.7718 14.1198 14.21L18.7098 9.61998C18.8961 9.43262 19.0006 9.17917 19.0006 8.91498C19.0006 8.6508 18.8961 8.39734 18.7098 8.20998Z"
                          fill="#9FABAF"
                        />
                      </svg>
                    )}
                  >
                    {[1, 2, 3, 4, 5].map((turno, i) => (
                      <MenuItem value={turno} key={i}>
                        <img
                          className="turno-option"
                          src={getIconUrl(turno)}
                          alt=""
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <div className="value-radio">
                    <input
                      type="radio"
                      id="unique"
                      name="price"
                      value="unique"
                      checked={isUnique}
                      onChange={() => {
                        methods.setValue(TurnoFields.Unique, true);
                      }}
                    />
                    <label htmlFor="unique">Valor único</label>
                    <input
                      type="radio"
                      id="differentiated"
                      name="price"
                      value="differentiated"
                      checked={!isUnique}
                      onChange={() => {
                        methods.setValue(TurnoFields.Unique, false);
                      }}
                    />
                    <label htmlFor="differentiated">Valor diferenciado</label>
                  </div>
                </Grid>
                {isUnique ? (
                  <Grid item xs={2}>
                    <MoneyField name={TurnoFields.UniqueValue} />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <div className="value-by-day">
                      <MoneyField name={TurnoFields.MondayValue} label="SEG" />
                      <MoneyField name={TurnoFields.TuesdayValue} label="TER" />
                      <MoneyField
                        name={TurnoFields.WednesdayValue}
                        label="QUA"
                      />
                      <MoneyField
                        name={TurnoFields.ThursdayValue}
                        label="QUI"
                      />
                      <MoneyField name={TurnoFields.FridayValue} label="SEX" />
                      <MoneyField
                        name={TurnoFields.SaturdayValue}
                        label="SAB"
                      />
                      <MoneyField name={TurnoFields.SundayValue} label="DOM" />
                    </div>
                  </Grid>
                )}
              </Grid>
            </>
          )}
          {page === 2 && (
            <>
              <span style={{ fontSize: '1rem' }}>
                Adicione a quantidade de vagas necessárias para esse turno
              </span>
              <br />
              <br />
              <br />
              <table className="number-of-shifts" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ width: '2rem' }}></th>
                    <th>DOM</th>
                    <th>SEG</th>
                    <th>TER</th>
                    <th>QUA</th>
                    <th>QUI</th>
                    <th>SEX</th>
                    <th>SAB</th>
                    <th style={{ width: '1rem' }}></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{ textAlign: 'center' }}
                      rowSpan={(data?.numberOfShifts ?? 0) + 1}
                    >
                      <span style={{ color: 'var(--gray600)' }}>
                        {methods.getValues(TurnoFields.StartDate)}
                      </span>
                      <br />
                      <br />
                      <img
                        src={getIconUrl(methods.getValues(TurnoFields.Icon))}
                        alt=""
                      />
                      <br />
                      <br />
                      <span style={{ color: 'var(--gray600)' }}>
                        {methods.getValues(TurnoFields.EndDate)}
                      </span>
                    </td>
                    <td colSpan={8}>
                      <table style={{ width: '100%' }}>
                        <>
                          {(() => {
                            const ret = [];
                            for (let i = 0; i < +(numberOfShifts ?? 0); i++) {
                              ret.push(
                                <tr>
                                  <td>
                                    <div className="shift-box"></div>
                                  </td>
                                  <td>
                                    <div className="shift-box"></div>
                                  </td>
                                  <td>
                                    <div className="shift-box"></div>
                                  </td>
                                  <td>
                                    <div className="shift-box"></div>
                                  </td>
                                  <td>
                                    <div className="shift-box"></div>
                                  </td>
                                  <td>
                                    <div className="shift-box"></div>
                                  </td>
                                  <td>
                                    <div className="shift-box"></div>
                                  </td>
                                  <td style={{ width: '1rem' }}>
                                    {i === +numberOfShifts - 1 && (
                                      <svg
                                        onClick={() => {
                                          methods.setValue(
                                            TurnoFields.NumberOfShifts,
                                            String(+numberOfShifts + -1),
                                          );
                                        }}
                                        className="delete-shift-box"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M17.9998 6C17.8123 5.81253 17.558 5.70721 17.2928 5.70721C17.0277 5.70721 16.7733 5.81253 16.5858 6L11.9998 10.586L7.41382 6C7.22629 5.81253 6.97198 5.70721 6.70682 5.70721C6.44165 5.70721 6.18735 5.81253 5.99982 6C5.81235 6.18753 5.70703 6.44184 5.70703 6.707C5.70703 6.97217 5.81235 7.22647 5.99982 7.414L10.5858 12L5.99982 16.586C5.81235 16.7735 5.70703 17.0278 5.70703 17.293C5.70703 17.5582 5.81235 17.8125 5.99982 18C6.18735 18.1875 6.44165 18.2928 6.70682 18.2928C6.97198 18.2928 7.22629 18.1875 7.41382 18L11.9998 13.414L16.5858 18C16.7733 18.1875 17.0277 18.2928 17.2928 18.2928C17.558 18.2928 17.8123 18.1875 17.9998 18C18.1873 17.8125 18.2926 17.5582 18.2926 17.293C18.2926 17.0278 18.1873 16.7735 17.9998 16.586L13.4138 12L17.9998 7.414C18.1873 7.22647 18.2926 6.97217 18.2926 6.707C18.2926 6.44184 18.1873 6.18753 17.9998 6Z"
                                          fill="#737B80"
                                        />
                                      </svg>
                                    )}
                                  </td>
                                </tr>,
                              );
                            }
                            ret.push(
                              <tr>
                                <td colSpan={7}>
                                  <div
                                    className="shift-box-add"
                                    onClick={() => {
                                      methods.setValue(
                                        TurnoFields.NumberOfShifts,
                                        String(+numberOfShifts + 1),
                                      );
                                    }}
                                  >
                                    <img src={addIcon} alt="" />
                                  </div>
                                </td>
                              </tr>,
                            );

                            return <tbody>{ret}</tbody>;
                          })()}
                        </>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          <div className="buttons-row">
            <Button disabled={false} onClick={onCancel} color="destructive">
              Cancelar
            </Button>
            {page === 1 && (
              <Button
                disabled={false}
                type="button"
                onClick={() => {
                  methods.handleSubmit((e) => {
                    setPage(2);
                  }, onError)();
                }}
              >
                Continuar
              </Button>
            )}
            {page === 2 && (
              <div>
                <Button
                  disabled={false}
                  color="secondary"
                  type="button"
                  onClick={() => {
                    setPage(1);
                  }}
                >
                  Voltar
                </Button>
                <Button
                  disabled={!+numberOfShifts}
                  isLoading={isSaving}
                  onClick={() => {
                    mutateSaveTurno({
                      ...methods.getValues(),
                    });
                  }}
                >
                  Salvar
                </Button>
              </div>
            )}
          </div>
        </Form>
      </FormProvider>
    </>
  );
}

export default AddTurno;
